import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateBrandFunction } from '../../redux/brand/brandSlice';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const EditBrandList = ({ brand }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: '',
        logo: '',
        status: false
    });

    useEffect(() => {
        console.log("Received brand:", brand);
        if (brand) {
            setFormData({
                title: brand.title || '',
                logo: brand.logo || '',
                status:brand.status || false
            });
        }
    }, [brand]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleStatusChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            status: e.target.checked
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (brand && brand._id) {
            try {
                await dispatch(updateBrandFunction({
                    _id: brand._id,
                    ...formData
                })).unwrap();
                
                MySwal.fire({
                    icon: 'success',
                    title: 'Updated!',
                    text: 'brand has been updated successfully.',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });

                document.querySelector('[data-bs-dismiss="modal"]').click();
            } catch (error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to update brand. Please try again.',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-danger'
                    }
                });
            }
        } else {
            console.error('brand or brand _id is missing');
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'brand data is incomplete. Please try again.',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-danger'
                }
            });
        }
    };

    if (!brand) {
        return null;
    }

    return (
        <div>
            <div className="modal fade" id="edit-brand">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Brand</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Brand Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                value={formData.title}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Brand Logo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="logo"
                                                value={formData.logo}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-0">
                                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="user3"
                                                    className="check"
                                                    checked={formData.status}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="user3" className="checktoggle" />
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-submit">
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EditBrandList.propTypes = {
    brand: PropTypes.shape({
        _id: PropTypes.string,
        title: PropTypes.string,
        logo: PropTypes.string,
        status: PropTypes.bool
    })
};

export default EditBrandList;