import React, { useState, useEffect } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Signin = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const storedAttempts = localStorage.getItem('failedAttempts');
    const storedBlockedTime = localStorage.getItem('blockedUntil');
    
    if (storedAttempts) {
      setFailedAttempts(parseInt(storedAttempts));
    }
    
    if (storedBlockedTime && new Date().getTime() < parseInt(storedBlockedTime)) {
      setIsBlocked(true);
    } else {
      localStorage.removeItem('blockedUntil');
      setIsBlocked(false);
    }
  }, []);

  const showSuccessMessage = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Success!',
      text: 'You are logged in successfully.',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-success'
      }
    });
  };

  const showErrorMessage = () => {
    MySwal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Wrong credentials. Please try again.',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-danger'
      }
    });
  };

  const showWarningMessage = () => {
    MySwal.fire({
      icon: 'warning',
      title: 'Warning!',
      text: 'This is your last attempt before the account is temporarily blocked.',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-warning'
      }
    });
  };

  const showBlockedMessage = () => {
    MySwal.fire({
      icon: 'error',
      title: 'Account Blocked',
      text: 'Too many failed attempts. Your account is temporarily blocked for 30 minutes.',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-danger'
      }
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    
    if (isBlocked) {
      showBlockedMessage();
      return;
    }

    if (email === "111111" && password === "111111") {
      showSuccessMessage();
      setFailedAttempts(0);
      localStorage.setItem('failedAttempts', '0');
      navigate(all_routes.dashboard);
    } else {
      const newFailedAttempts = failedAttempts + 1;
      setFailedAttempts(newFailedAttempts);
      localStorage.setItem('failedAttempts', newFailedAttempts.toString());

      if (newFailedAttempts === 3) {
        showWarningMessage();
      } else if (newFailedAttempts >= 4) {
        setIsBlocked(true);
        const blockedUntil = new Date().getTime() + 30 * 60 * 1000; // 30 minutes
        localStorage.setItem('blockedUntil', blockedUntil.toString());
        showBlockedMessage();
      } else {
        showErrorMessage();
      }
    }
  };

  const route = all_routes;
  
  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            <form onSubmit={handleSignIn}>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  {/* <img src="assets/img/logo.png" alt="img" /> */}
                </div>
                <Link to={route.dashboard} className="login-logo logo-white">
                  <img src="assets/img/logo-white.png" alt="" />
                </Link>
                <div className="login-userheading">
                  <h3>Sign In</h3>
                  <h4>
                    Access the Dreamspos panel using your email and passcode.
                  </h4>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Email Address</label>
                  <div className="form-addons">
                    <input 
                      type="text" 
                      className="form-control" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={isBlocked}
                    />
                    <ImageWithBasePath
                      src="assets/img/icons/mail.svg"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Password</label>
                  <div className="pass-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="pass-input form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isBlocked}
                    />
                    <span
                      className={`fas toggle-password ${
                        isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <div className="custom-control custom-checkbox">
                        <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" className="form-control" />
                          <span className="checkmarks" />
                          Remember me
                        </label>
                      </div>
                      <div className="text-end">
                        <Link className="forgot-link" to={route.forgotPassword}>
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <button type="submit" className="btn btn-login" disabled={isBlocked}>
                    Sign In
                  </button>
                </div>
                <div className="signinform">
                  <h4>
                    New on our platform?
                    <Link to={route.register} className="hover-a">
                      {" "}
                      Create an account
                    </Link>
                  </h4>
                </div>
                <div className="form-setlogin or-text">
                  <h4>OR</h4>
                </div>
                <div className="form-sociallink">
                  <ul className="d-flex">
                    <li>
                      <Link to="#" className="facebook-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/facebook-logo.svg"
                          alt="Facebook"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/google.png"
                          alt="Google"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="apple-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/apple-logo.svg"
                          alt="Apple"
                        />
                      </Link>
                    </li>
                  </ul>
                  <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                    <p>Copyright © 2024 KianntechnologyPOS. All rights reserved</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;