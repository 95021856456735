import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCategoryFunction } from '../../redux/category/categorySlice';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const EditCategoriesList = ({ category }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        slug: '',
        status: false
    });

    useEffect(() => {
        console.log("Received subcategory:", category);
        if (category) {
            setFormData({
                name: category.name || '',
                slug: category.slug || '',
                status:category.status || false
            });
        }
    }, [category]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleStatusChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            status: e.target.checked
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (category && category._id) {
            try {
                await dispatch(updateCategoryFunction({
                    _id: category._id,
                    ...formData
                })).unwrap();
                
                MySwal.fire({
                    icon: 'success',
                    title: 'Updated!',
                    text: 'category has been updated successfully.',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });

                document.querySelector('[data-bs-dismiss="modal"]').click();
            } catch (error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to update category. Please try again.',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-danger'
                    }
                });
            }
        } else {
            console.error('category or category _id is missing');
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'category data is incomplete. Please try again.',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-danger'
                }
            });
        }
    };

    if (!category) {
        return null;
    }

    return (
        <div>
            <div className="modal fade" id="edit-category">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Category</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Category Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Category Slug</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="slug"
                                                value={formData.slug}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-0">
                                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="user3"
                                                    className="check"
                                                    checked={formData.status}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="user3" className="checktoggle" />
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-submit">
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EditCategoriesList.propTypes = {
    category: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        status: PropTypes.bool
    })
};

export default EditCategoriesList;