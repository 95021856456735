import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://hrithik-pos.vercel.app/api/category";

// Create category
export const addCategoryFunction = createAsyncThunk( "category/create",async (data, { rejectWithValue }) => {
    console.log("Post FData:", data)
    try {
      const response = await axios.post(BASE_URL, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch categories
export const FetchCategoryFunction = createAsyncThunk("category/fetchAll",async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(BASE_URL);
      console.log("Fetched data:", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update category
export const updateCategoryFunction = createAsyncThunk("category/update",async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/${data._id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete category
export const deleteCategoryFunction = createAsyncThunk("category/delete",async (_id, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/${_id}`);
      return _id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    loading: false,
    error: null,
    searchResults: [],
  },
  reducers: {
    searchCategory: (state, action) => {
      state.searchResults = state.categories.filter(category => 
        category.name.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(addCategoryFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCategoryFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.categories.push(action.payload);
      })
      .addCase(addCategoryFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Read
      .addCase(FetchCategoryFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchCategoryFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(FetchCategoryFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update
      .addCase(updateCategoryFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCategoryFunction.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.categories.findIndex(category => category._id === action.payload._id);
        if (index !== -1) {
          state.categories[index] = action.payload;
        }
      })
      .addCase(updateCategoryFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete
      .addCase(deleteCategoryFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategoryFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = state.categories.filter(category => category._id !== action.payload);
      })
      .addCase(deleteCategoryFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { searchCategory } = categorySlice.actions;
export default categorySlice.reducer;