import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  ChevronUp,
  MinusCircle,
  PlusCircle,
  RotateCcw,
  Search,
} from "feather-icons-react/build/IconComponents";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import QRcodeModelPopup from "../../core/modals/inventory/qrcode";
import { FetchProductFunction } from "../../core/redux/product/productSlice";
import allProducts from "./allProducts"

const Qrcode = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [localProducts, setLocalProducts] = useState([]);
  
  // const allProducts = useSelector((state) => state.product.product);
  const data = useSelector((state) => state.toggle_header);

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    dispatch(FetchProductFunction());
  }, [dispatch]);

  useEffect(() => {
    setLocalProducts(allProducts.map(product => ({...product, quantity: 1})));
  }, [allProducts]);

  const filteredProducts = localProducts.filter(product => 
    product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.barcodesymbol.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const selectstore = [
    { value: "choose", label: "Choose" },
    { value: "thomas", label: "Thomas" },
    { value: "hritik", label: "Hritik" },
    { value: "rasmussen", label: "Rasmussen" },
    { value: "fredJohn", label: "Fred John" },
    { value: "hritikbro", label: "Hritik bro" },
    { value: "Lalit", label: "Lalit" },
    { value: "Kuldeep", label: "Kuldeep" },
    { value: "ankir", label: "Ankit" },
  ];
  const warehouseitems = [
    { value: "choose", label: "Choose" },
    { value: "legendary", label: "Legendary" },
    { value: "determined", label: "Determined" },
    { value: "sincere", label: "Sincere" },
  ];
  const papersize = [
    { value: "choose", label: "Choose" },
    { value: "Recent1", label: "Recent1" },
  ];

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedPaperSize, setSelectedPaperSize] = useState(null);
  // const [showStoreName, setShowStoreName] = useState(true);
  const [showProductName, setShowProductName] = useState(true);
  // const [showPrice, setShowPrice] = useState(true);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (productId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveProduct(productId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your product has been removed.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setLocalProducts(prevProducts =>
      prevProducts.map(p =>
        p._id === productId ? { ...p, quantity: Math.max(1, newQuantity) } : p
      )
    );
  };

  const handleRemoveProduct = (productId) => {
    setLocalProducts(prevProducts =>
      prevProducts.filter(p => p._id !== productId)
    );
  };

  const handleResetBarcode = () => {
    setSearchQuery("");
    setSelectedWarehouse(null);
    setSelectedStore(null);
    setSelectedPaperSize(null);
    setLocalProducts(allProducts.map(product => ({...product, quantity: 1})));
  };

  return (
    <div className="page-wrapper notes-page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Print QRcode</h4>
              <h6>Manage your QRcodes</h6>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
        </div>
        <div className="barcode-content-list">
          <form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="row seacrh-barcode-item">
                  <div className="col-sm-6 mb-3 seacrh-barcode-item-one">
                    <label className="form-label">Warehouse</label>
                    <Select
                      classNamePrefix="react-select"
                      options={warehouseitems}
                      value={selectedWarehouse}
                      onChange={setSelectedWarehouse}
                      placeholder="Choose"
                    />
                  </div>
                  <div className="col-sm-6 mb-3 seacrh-barcode-item-one">
                    <label className="form-label">Select Store</label>
                    <Select
                      classNamePrefix="react-select"
                      options={selectstore}
                      value={selectedStore}
                      onChange={setSelectedStore}
                      placeholder="Choose"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="input-blocks search-form seacrh-barcode-item">
                  <div className="searchInput">
                    <label className="form-label">Product</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Product by Name, SKU, or Code"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <div className="icon">
                      <Search className="fas fa-search" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="col-lg-12">
            <div className="modal-body-table search-modal-header">
              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>SKU</th>
                      <th>SubCategory</th>
                      <th>Code</th>
                      <th>Qty</th>
                      <th className="text-center no-sort">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProducts.slice(0,2).map((product) => (
                      <tr key={product._id}>
                        <td>
                          <div className="productimgname">
                            <Link to="#" className="product-img stock-img">
                              <img
                                src={product.image}
                                alt={product.title}
                              />
                            </Link>
                            <Link to="#" style={{ fontWeight: 600, color: "black" }}>{product.title}</Link>
                          </div>
                        </td>
                        <td>{product.sku}</td>
                        <td>{product.subcategory}</td>
                        <td>{product.barcodesymbol}</td>
                        <td>
                          <div className="product-quantity">
                            <span
                              className="quantity-btn"
                              onClick={() =>
                                handleQuantityChange(
                                  product._id,
                                  product.quantity - 1
                                )
                              }
                            >
                              <MinusCircle />
                            </span>
                            <input
                              type="text"
                              className="quantity-input"
                              value={product.quantity}
                              readOnly
                            />
                            <span
                              className="quantity-btn"
                              onClick={() =>
                                handleQuantityChange(
                                  product._id,
                                  product.quantity + 1
                                )
                              }
                            >
                              <PlusCircle />
                            </span>
                          </div>
                        </td>
                        <td className="action-table-data justify-content-center">
                          <div className="edit-delete-action">
                            <Link
                              onClick={() => showConfirmationAlert(product._id)}
                              className="barcode-delete-icon confirm-text"
                              to="#"
                            >
                              <i className="feather-trash-2" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="paper-search-size">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <form className="mb-0">
                  <label className="form-label">Paper Size</label>
                  <Select
                    classNamePrefix="react-select"
                    options={papersize}
                    value={selectedPaperSize}
                    onChange={setSelectedPaperSize}
                    placeholder="Choose"
                  />
                </form>
              </div>
              <div className="col-lg-6 pt-3">
                <div className="row">
                 
                  <div className="col-sm-4">
                    <div className="search-toggle-list">
                      <p>Show Product Name</p>
                      <div className="input-blocks m-0">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <input
                            type="checkbox"
                            id="user8"
                            className="check"
                            checked={showProductName}
                            onChange={(e) =>
                              setShowProductName(e.target.checked)
                            }
                          />
                          <label htmlFor="user8" className="checktoggle mb-0">
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          <div className="search-barcode-button">
            <Link
              to="#"
                className="btn btn-submit me-2"
                data-bs-toggle="modal"
                data-bs-target="#prints-barcode"
            >
              <span>
                <i className="fas fa-eye me-2" />
              </span>
              Generate QRcode
            </Link>
            <Link
              to="#"
              className="btn btn-cancel me-2"
              onClick={handleResetBarcode}
            >
              <span>
                <i className="fas fa-power-off me-2" />
              </span>
              Reset QRcode
            </Link>
            <Link to="#" className="btn btn-cancel close-btn" onClick={handlePrint}>
              <span>
                <i className="fas fa-print me-2" />
              </span>
              Print QRcode
            </Link>
          </div>
        </div>
      </div>
       <QRcodeModelPopup 
          selectedProducts={filteredProducts}
          showProductName={showProductName}
          />
    </div>
  );
};

export default Qrcode;