import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
// import { Link } from 'react-router-dom'
import Select from 'react-select'
import { addSubcategory } from '../../redux/subcategory/subcategorySlice' // Import the addSubcategory action
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const AddSubcategory = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        category: '',
        name: '',
        code: '',
        description: '',
        status: true
    })

    const categories = [
        { value: 'Choose Category', label: 'Choose Category' },
        { value: 'Category', label: 'Category' },
        { value: 'Iphone', label: 'Iphone' },
        { value: 'machine', label: 'Machine' },
        { value: 'tely', label: 'Telll' }
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleCategoryChange = (selectedOption) => {
        setFormData(prevState => ({
            ...prevState,
            category: selectedOption.value
        }))
    }

    const handleStatusChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            status: e.target.checked
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(addSubcategory(formData))
    }

    return (
        <div>
            {/* Add Category */}
            <div className="modal fade" id="add-category">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Create Sub Category</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Parent Category</label>
                                            <Select
                                                classNamePrefix="react-select"
                                                options={categories}
                                                placeholder="Newest"
                                                onChange={handleCategoryChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Category Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Category Code</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="code"
                                                value={formData.code}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3 input-blocks">
                                            <label className="form-label">Description</label>
                                            <textarea 
                                                className="form-control" 
                                                name="description"
                                                value={formData.description}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-0">
                                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="user2"
                                                    className="check"
                                                    checked={formData.status}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="user2" className="checktoggle" />
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-submit">
                                                Create Subcategory
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

            {/* /Add Category */}
        </div>
    )
}

export default AddSubcategory