// src/store/posSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import products from '../products';

export const fetchProducts = createAsyncThunk('pos/fetchProducts', async () => {
  return products;
});

export const posSlice = createSlice({
  name: 'pos',
  initialState: {
    products: [],
    customers: [
      { value: 'walkInCustomer', label: 'Walk in Customer' },
      { value: 'john', label: 'John' },
    ],
    selectedCustomer: null,
    gst: [
      { value: '5', label: 'GST 5%' },
      { value: '10', label: 'GST 10%' },
      { value: '15', label: 'GST 15%' },
    ],
    shipping: [{ value: 15, label: '15' }],
    discount: [
      { value: '0', label: 'No Discount' },
      { value: 10, label: '10%' },
      { value: 20, label: '20%' },
      { value: 30, label: '30%' },
    ],
    selectedDiscount: 0,
    tax: [
      { value: 'exclusive', label: 'Exclusive' },
      { value: 'inclusive', label: 'Inclusive' },
    ],
    discountType: [
      { value: 'percentage', label: 'Percentage' },
      { value: 'earlyPaymentDiscounts', label: 'Early payment discounts' },
    ],
    units: [
      { value: 'kilogram', label: 'Kilogram' },
      { value: 'grams', label: 'Grams' },
    ],
    cart: [],
    total: 0,
    activeCategory: 'all',
  },
  reducers: {
    addToCart: (state, action) => {
      const product = action.payload;
      const existingProduct = state.cart.find((item) => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        state.cart.push({ ...product, quantity: 1 });
      }
      state.total += product.price;
    },
    removeFromCart: (state, action) => {
      const productId = action.payload;
      const existingProduct = state.cart.find((item) => item.id === productId);
      if (existingProduct) {
        if (existingProduct.quantity > 1) {
          existingProduct.quantity -= 1;
        } else {
          state.cart = state.cart.filter((item) => item.id !== productId);
        }
        state.total -= existingProduct.price;
      }
    },
    clearCart: (state) => {
      state.cart = [];
      state.total = 0;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    buyCart: (state) => {
      // Implement payment and order processing logic here
      state.cart = [];
      state.total = 0;
    },
    setSelectedDiscount: (state, action) => {
      state.selectedDiscount = action.payload;
    },
    calculateTotal: (state) => {
      let newTotal = 0;
      state.cart.forEach((item) => {
        const discountedPrice = item.price * (1 - state.selectedDiscount / 100);
        newTotal += discountedPrice * item.quantity;
      });
      state.total = newTotal;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  setSelectedCustomer,
  setActiveCategory,
  buyCart,
  setSelectedDiscount,
  calculateTotal,
} = posSlice.actions;
export default posSlice.reducer;