import React from "react";
import { addCategoryFunction } from "../../redux/category/categorySlice";
import {useDispatch} from "react-redux";

const AddCategoryList = () => {
    const dispatch = useDispatch()
  const [formData, setFormData] = React.useState({
      name: '',
      slug: '',
      status: true
  })
  const handleInputChange = (e) => {
      const { name, value } = e.target
      setFormData(prevState => ({
          ...prevState,
          [name]: value
      }))
  }

  const handleStatusChange = (e) => {
      setFormData(prevState => ({
          ...prevState,
          status: e.target.checked
      }))
  }

  const handleSubmit = (e) => {
    console.log("button clicked")
      e.preventDefault()
      dispatch(addCategoryFunction(formData))
  }
    return (
        <div>
            {/* Add Category */}
            <div className="modal fade" id="add-category">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Create Category</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Category</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Category Slug</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="slug"
                                                value={formData.slug}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-0">
                                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="user2"
                                                    className="check"
                                                    checked={formData.status}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="user2" className="checktoggle" />
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-submit">
    Create Category
</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Category */}
        </div>
    )
}

export default AddCategoryList
