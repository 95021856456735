import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const QRcodeModelPopup = ({ selectedProducts, showStoreName, showPrice }) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      {/* Print Barcode */}
      <div className="modal fade" id="prints-barcode">
        <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>QR Code</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="d-flex justify-content-end">
                    <Link
                      to="#"
                      className="btn btn-cancel close-btn"
                      onClick={handlePrint}
                    >
                      <span>
                        <i className="fas fa-print me-2" />
                      </span>
                      Print QRcode
                    </Link>
                  </div>
                  {selectedProducts.map((product) => (
                    <div key={product.id}>
                      <div className="barcode-scan-header">
                        <h5>{product.title}</h5>
                      </div>
                      <div className="row">
                        {Array(product.quantity).fill().map((_, index) => (
                          <div className="col-sm-4" key={index}>
                            <div className="barcode-scanner-link text-center">
                              {showStoreName && <h6>{product.store}</h6>}
                              {/* {showProductName && <p>{product.title}</p>} */}
                              {showPrice && <p>Price: ${product.price}</p>}
                              <div className="barscaner-img">
                                <img
                                                            src="./assets/img/barcode/qr-code.png"
                                                            alt="Barcode"
                                                            className="img-fluid"
                                                        />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Print Barcode */}
    </div>
  )
}

QRcodeModelPopup.propTypes = {
  selectedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  showStoreName: PropTypes.bool.isRequired,
  showProductName: PropTypes.bool.isRequired,
  showPrice: PropTypes.bool.isRequired,
}

export default QRcodeModelPopup