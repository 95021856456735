const allProduct = [
    {
        "_id": "66c608c8cb1a3bb7b3b2a70e",
        "title": "tandoori chicken",
        "store": "thomas",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "tandoori-chicken",
        "created": "admin",
        "sku": "85123",
        "subcategory": "test pro production",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very tasty",
        "manudate": "31 december",
        "exdate": "3 january",
        "price": 600,
        "category": "Test 3",
        "brand": "Nike",
        "quantity": 34,
        "sold": null,
        "image": "https://tse2.mm.bing.net/th?id=OIP.21xfGQ9jUczQhBfBTgASHwHaEJ&pid=Api&P=0&h=180",
        "images": [],
        "color": [],
        "tags": "",
        "totalrating": "",
        "ratings": [],
        "createdAt": "2024-08-21T15:33:28.190Z",
        "updatedAt": "2024-08-21T15:33:28.190Z"
    },
    {
        "_id": "66c6dc90296acd06d86057a6",
        "title": "sussie",
        "store": "mmmm",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "sussie",
        "created": "admin",
        "sku": "19141",
        "subcategory": "iPhone 12 Pro",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very good",
        "manudate": "31 december",
        "exdate": "3 january",
        "price": 2000,
        "category": "Test 2",
        "brand": "Nike",
        "quantity": 10,
        "sold": null,
        "image": "https://tse1.mm.bing.net/th?id=OIP.wotLywG7CivyKWoCVM7R0QHaED&pid=Api&P=0&h=180",
        "images": [],
        "color": [],
        "tags": "",
        "ratings": [],
        "totalrating": "",
        "createdAt": "2024-08-21T15:29:00.999Z",
        "updatedAt": "2024-08-21T15:29:00.999Z"
    },
    {
        "_id": "66c6dcf4296acd06d86057a8",
        "title": "tommato",
        "store": "hlon",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "tommato",
        "created": "admin",
        "sku": "19141",
        "subcategory": "iPhone 12 Pro",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very good",
        "manudate": "31 december",
        "exdate": "3 january",
        "price": 2000,
        "category": "Test 2",
        "brand": "Nike",
        "quantity": 10,
        "sold": null,
        "image": "https://tse1.mm.bing.net/th?id=OIP.ki2utRSQ3Oz6hkggAM3b4QHaE9&pid=Api&rs=1&c=1&qlt=95&h=180",
        "images": [],
        "color": [],
        "tags": "",
        "ratings": [],
        "totalrating": "",
        "createdAt": "2024-08-21T15:29:00.999Z",
        "updatedAt": "2024-08-21T15:29:00.999Z"
    },
    {
        "_id": "66c6dd60296acd06d86057ae",
        "title": "Icecream",
        "store": "hlo33n",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "Icecream",
        "created": "admin",
        "sku": "19141",
        "subcategory": "iPhone 12 Pro",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "344444",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very good",
        "manudate": "31 december",
        "exdate": "3 january",
        "price": 2000,
        "category": "Test 2",
        "brand": "Nike",
        "quantity": 10,
        "sold": null,
        "image": "https://tse1.mm.bing.net/th?id=OIP.-f26eNtg6Zs3ioaiG-lYEQAAAA&pid=Api&P=0&h=180",
        "images": [],
        "color": [],
        "tags": "",
        "ratings": [],
        "totalrating": "",
        "createdAt": "2024-08-21T15:29:00.999Z",
        "updatedAt": "2024-08-21T15:29:00.999Z"
    },
    {
        "_id": "66c6ddba296acd06d86057b2",
        "title": "Mutton curry",
        "store": "hllo33n",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "Mutton-curry",
        "created": "admin",
        "sku": "19141",
        "subcategory": "iPhone 12 Pro",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "344444",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very good",
        "manudate": "31 december",
        "exdate": "3 january",
        "price": 2000,
        "category": "Test 2",
        "brand": "Nike",
        "quantity": 10,
        "sold": null,
        "image": "https://tse3.mm.bing.net/th?id=OIP.lEzRybhAQtUHeNyzYCLxVgHaE8&pid=Api&P=0&h=180",
        "images": [],
        "color": [],
        "tags": "",
        "ratings": [],
        "totalrating": "",
        "createdAt": "2024-08-21T15:29:00.999Z",
        "updatedAt": "2024-08-21T15:29:00.999Z"
    },
    {
        "_id": "66c6ee7764444947d3d07a68",
        "title": "Mutton curry",
        "store": "hllo333n",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "Mutton-curry",
        "created": "admin",
        "sku": "19141",
        "subcategory": "iPhone 12 Pro",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "344444",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very good",
        "manudate": "31 december",
        "exdate": "3 january 2023",
        "price": 2000,
        "category": "Test 2",
        "brand": "Nike",
        "quantity": 10,
        "sold": null,
        "image": "https://tse3.mm.bing.net/th?id=OIP.lEzRybhAQtUHeNyzYCLxVgHaE8&pid=Api&P=0&h=180",
        "images": [],
        "color": [],
        "tags": "",
        "ratings": [],
        "totalrating": "",
        "createdAt": "2024-08-21T15:29:00.999Z",
        "updatedAt": "2024-08-21T15:29:00.999Z"
    },
    {
        "_id": "66c6fdb92a5dad8dc9f7b2b2",
        "title": "Meggie",
        "store": "hllo3343n",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "Meggie",
        "created": "admin",
        "sku": "19141",
        "subcategory": "iPhone 12 Pro",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "344444",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very good",
        "manudate": "20 december 2023",
        "exdate": "21 january 2023",
        "price": 2000,
        "category": "Test 2",
        "brand": "Nike",
        "quantity": 10,
        "sold": null,
        "image": "https://vegecravings.com/wp-content/uploads/2020/01/Vegetable-Masala-Maggi-Recipe-Step-By-Step-Instructions-scaled.jpg",
        "images": [],
        "color": [],
        "tags": "",
        "ratings": [],
        "totalrating": "",
        "createdAt": "2024-08-21T15:29:00.999Z",
        "updatedAt": "2024-08-21T15:29:00.999Z"
    },
    {
        "_id": "66c6fe232a5dad8dc9f7b2ba",
        "title": "Idle",
        "store": "hllo33433n",
        "taxtype": "salesTax",
        "warehouse": "legendary",
        "slug": "Idle",
        "created": "admin",
        "sku": "19141",
        "subcategory": "iPhone 12 Pro",
        "sellingtype": "transactionalSelling",
        "barcodesymbol": "code35",
        "itemcode": "344444",
        "discounttype": "percentage",
        "discountvalue": "44",
        "quantityalert": "30",
        "description": "very good",
        "manudate": "20 august 2024",
        "exdate": "21 august 2024",
        "price": 2000,
        "category": "Test 2",
        "brand": "Nike",
        "quantity": 10,
        "sold": null,
        "image": "https://www.thestatesman.com/wp-content/uploads/2019/04/idli.jpg",
        "images": [],
        "color": [],
        "tags": "",
        "ratings": [],
        "totalrating": "",
        "createdAt": "2024-08-21T15:29:00.999Z",
        "updatedAt": "2024-08-21T15:29:00.999Z"
    }
]

export default allProduct