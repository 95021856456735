import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
import Addunits from "../../core/modals/inventory/addunits";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../core/redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FetchCategoryFunction } from "../../core/redux/category/categorySlice";
import { FetchBrandFunction } from "../../core/redux/brand/brandSlice";
import { fetchSubcategories } from "../../core/redux/subcategory/subcategorySlice";
import { addProductFunction } from "../../core/redux/product/productSlice";
import { uploadImages } from "../../core/redux/imagesupload/imageSlice";
import { useNavigate } from 'react-router-dom';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const AddProduct = () => {

  const [formData, setFormData] = React.useState({
    title: '',
    store: '',
    warehouse: "",
    slug:"",
    sku:"",
    subcategory:"",
    sellingtype:"",
    barcodesymbol:"",
    itemcode:"",
    discounttype:"",
    discountvalue:"",
    quantityalert:"",
    description: "",
    price: "",
    category: "",
    brand: "",
    quantity: "",
    sold: "",
    tags: "",
    totalrating: "",
    taxtype:"",
    manudate:"",
    exdate:"",
    images:[],
    image:""
  })
  const [selectedFiles, setSelectedFiles] = useState([]);
  const route = all_routes;
  const dispatch = useDispatch();
  React.useEffect(()=>{
    dispatch(FetchCategoryFunction())
    dispatch(FetchBrandFunction());
    dispatch(fetchSubcategories())
  },[])
  const navigate = useNavigate();
  const data = useSelector((state) => state.toggle_header);
  const category = useSelector((state)=> state.category.categories);
  const subcategory = useSelector((state) => state.subcategory.subcategories)
  const brand = useSelector((state)=> state.brand.brand);
  const uploadedImages = useSelector(state => state.images.images);
  const status = useSelector(state => state.images.status);
  const error = useSelector(state => state.images.error);

  const categoryOptions = category?.map(category => ({
    value: category.name,
    label: category.name
}));
  const subcategoryOptions = subcategory?.map(subcategory => ({
    value: subcategory.name,
    label: subcategory.name
  }));
  const brandcategoryOptions = brand?.map(brand => ({
    value: brand.title,
    label: brand.title
  }));

  const generateRandomCode = () => {
    const randomCode = Math.floor(10000 + Math.random() * 90000).toString();

    setFormData(prevState => ({
      ...prevState,
      sku: randomCode
    }));
  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    
    const formData = new FormData();
    files.forEach(file => {
      formData.append('images', file);
    });
    dispatch(uploadImages(formData));
  };

  const handleRemoveImage = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };
  
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const store = [
    { value: "choose", label: "Choose" },
    { value: "thomas", label: "Thomas" },
    { value: "hritik", label: "Hritik" },
    { value: "rasmussen", label: "Rasmussen" },
    { value: "fredJohn", label: "Fred John" },
    { value: "hritikbro", label: "Hritik bro" },
    { value: "Lalit", label: "Lalit" },
    { value: "Kuldeep", label: "Kuldeep" },
    { value: "ankir", label: "Ankit" },
  ];
  const warehouse = [
    { value: "choose", label: "Choose" },
    { value: "legendary", label: "Legendary" },
    { value: "determined", label: "Determined" },
    { value: "sincere", label: "Sincere" },
  ];

  const subsubcategories = [
    { value: "choose", label: "Choose" },
    { value: "legendary", label: "Legendary" },
    { value: "determined", label: "Determined" },
    { value: "sincere", label: "Sincere" },
  ];


  const sellingtype = [
    { value: "choose", label: "Choose" },
    { value: "transactionalSelling", label: "Transactional selling" },
    { value: "solutionSelling", label: "Solution selling" },
  ];
  const barcodesymbol = [
    { value: "choose", label: "Choose" },
    { value: "code34", label: "Code34" },
    { value: "code35", label: "Code35" },
    { value: "code36", label: "Code36" },
  ];
  const taxtype = [
    { value: "exclusive", label: "Exclusive" },
    { value: "salesTax", label: "Sales Tax" },
  ];
  const discounttype = [
    { value: "choose", label: "Choose" },
    { value: "percentage", label: "Percentage" },
    { value: "cash", label: "Cash" },
  ];
  const discounttype1 = [
    { value: "choose", label: "Choose" },
    { value: "percentage", label: "Percentage" },
    { value: "cash", label: "Cash" },
  ];


  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }))
}

const handleCategoryChange = (selectedOption) => {
    setFormData(prevState => ({
        ...prevState,
        category: selectedOption.value
    }))
}

const handleBrandChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      brand: selectedOption.value
  }))
}

const handleWarehouseChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      warehouse: selectedOption.value
  }))
}

const handleSubcategoryChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      subcategory: selectedOption.value
  }))
}


const handleStoreChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      store: selectedOption.value
  }))
}

const handleSubsubcategoryChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      subsubcategory: selectedOption.value
  }))
}

const handleSellingTypeChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      sellingtype: selectedOption.value
  }))
}

const handleBarcodeSymbolChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      barcodesymbol: selectedOption.value
  }))
}

const handleTaxtypeChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      taxtype: selectedOption.value
  }))
}


const handlediscountytpeChange = (selectedOption) => {
  setFormData(prevState => ({
      ...prevState,
      discounttype: selectedOption.value
  }))
}

const MySwal = withReactContent(Swal);
const handleSubmit = (e) => {
  e.preventDefault();
  dispatch(addProductFunction(formData))
    .then(() => {
      MySwal.fire({
        icon: 'success',
        title: 'Created!',
        text: 'Product has been updated successfully.',
        confirmButtonText: 'OK',
        customClass: {
            confirmButton: 'btn btn-success'
        }
    });
      navigate(route.productlist);
    })
    .catch((error) => {
      MySwal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to Create a Product. Please try again.',
        confirmButtonText: 'OK',
        customClass: {
            confirmButton: 'btn btn-danger'
        }
    });
      console.error(error);
    });
};
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>New Product</h4>
              <h6>Create new product</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Back to Product
                </Link>
              </div>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Collapse"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={() => {
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp className="feather-chevron-up" />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Product Information</span>
                        </h5>
                        <Link to="#">
                          <ChevronDown className="chevron-down-add" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Store</label>
                            <Select
                              classNamePrefix="react-select"
                              options={store}
                              placeholder="Choose"
                              onChange={handleStoreChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Warehouse</label>
                            <Select
                              classNamePrefix="react-select"
                              options={warehouse}
                              placeholder="Choose"
                              onChange={handleWarehouseChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Product Name</label>
                            <input type="text" 
                            className="form-control" 
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Slug</label>
                            <input type="text" className="form-control" 
                            name="slug"
                            value={formData.slug}
                            onChange={handleInputChange}/>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>SKU</label>
                            <input
                            type="text"
                            className="form-control list"
                            placeholder="Enter SKU"
                            value={formData.sku}
                            onChange={(e) => setFormData(prevState => ({
                              ...prevState,
                              sku: e.target.value
                            }))}
                          />
                             <Link
                              className="btn btn-primaryadd"
                              onClick={generateRandomCode}
                            >
                              Generate Code
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="addservice-info">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                          <div className="add-newplus">
                              <label className="form-label">Category</label>
                              <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-units-category"
                              >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Add New</span>
                              </Link>
                          </div>
                          <Select
                              classNamePrefix="react-select"
                              options={categoryOptions}
                              placeholder="Choose"
                              onChange={handleCategoryChange}
                          />
                      </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Sub Category</label>
                              <Select
                                classNamePrefix="react-select"
                                options={subcategoryOptions}
                                placeholder="Choose"
                                onChange={handleSubcategoryChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">
                                Sub Sub Category
                              </label>
                              <Select
                                classNamePrefix="react-select"
                                options={subsubcategories}
                                placeholder="Choose"
                                onChange={handleSubsubcategoryChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add-product-new">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                                      <div className="mb-3 add-product">
                        <div className="add-newplus">
                            <label className="form-label">Brand</label>
                            <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#add-units-category"
                            >
                                <PlusCircle className="plus-down-add" />
                                <span>Add New</span>
                            </Link>
                        </div>
                        <Select
                            classNamePrefix="react-select"
                            options={brandcategoryOptions}
                            placeholder="Choose"
                            onChange={handleBrandChange}
                        />
                    </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                           
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Selling Type</label>
                              <Select
                                classNamePrefix="react-select"
                                options={sellingtype}
                                placeholder="Choose"
                                onChange={handleSellingTypeChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Barcode Symbology
                            </label>
                            <Select
                              classNamePrefix="react-select"
                              options={barcodesymbol}
                              placeholder="Choose"
                              onChange={handleBarcodeSymbolChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>Item Code</label>
                            <input
                              type="text"
                              className="form-control list"
                              placeholder="Please Enter Item Code"
                            />
                            <Link
                              to={route.addproduct}
                              className="btn btn-primaryadd"
                            >
                              Generate Code
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* Editor */}
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <label>Description</label>
                          <textarea
                            className="form-control h-100"
                            rows={5}
                            defaultValue={""}
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                          />
                          <p className="mt-1">Maximum 60 Characters</p>
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample2"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list icon">
                          <h5>
                            <LifeBuoy className="add-info" />
                            <span>Pricing &amp; Stocks</span>
                          </h5>
                          <Link to="#">
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="input-blocks add-products">
                        <label className="d-block">Product Type</label>
                        <div className="single-pill-product">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-4 mb-0 active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="payment"
                                />
                                <span className="checkmark" /> Single Product
                              </span>
                            </li>
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-2 mb-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="sign"
                                />
                                <span className="checkmark" /> Variable Product
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Quantity</label>
                                <input type="text" className="form-control" name="quantity" value={formData.quantity}
                                onChange={handleInputChange}/>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Price</label>
                                <input type="text" className="form-control" 
                                name="price" value={formData.price} onChange={handleInputChange}/>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Tax Type</label>
                                <Select
                                  classNamePrefix="react-select"
                                  options={taxtype}
                                  placeholder="Select Option"
                                  onChange={handleTaxtypeChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Type</label>
                                <Select
                                  classNamePrefix="react-select"
                                  options={discounttype}
                                  placeholder="Choose"
                                  onChange={handlediscountytpeChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Value</label>
                                <input type="text" placeholder="Choose" name="discountvalue" value={formData.discountvalue} onChange={handleInputChange}/>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Quantity Alert</label>
                                <input type="text" className="form-control" name="quantityalert" value={formData.quantityalert} onChange={handleInputChange}/>
                              </div>
                            </div>
                          </div>
                          <div
                            className="accordion-card-one accordion"
                            id="accordionExample3"
                          >
                            <div className="accordion-item">
                              <div
                                className="accordion-header"
                                id="headingThree"
                              >
                                <div
                                  className="accordion-button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-controls="collapseThree"
                                >
                                  <div className="addproduct-icon list">
                                    <h5>
                                      <i
                                        data-feather="image"
                                        className="add-info"
                                      />
                                      <span>Images</span>
                                    </h5>
                                    <Link to="#">
                                      <ChevronDown className="chevron-down-add" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample3"
                              >
                                <div className="accordion-body">
                                  <div className="text-editor add-list add">
                                    <div className="col-lg-12">
                                    <div className="add-choosen">
      <div className="input-blocks">
        <div className="image-upload">
          <input type="file" multiple onChange={handleFileChange} />
          <div className="image-uploads">
            <PlusCircle className="plus-down-add me-0" />
            <h4>Add Images</h4>
          </div>
        </div>
      </div>
      <br /> 
      <div className="input-blocks">
      <div className="input-blocks add-product" style={{marginLeft:"420px" , marginTop:"30px"}}>
                                <label>Enter Image Url</label>
                                <input type="text" className="form-control" 
                                name="image" value={formData.image} onChange={handleInputChange}/>
                              </div>
      </div>
      {status === 'loading' && <p>Uploading...</p>}
      {error && <p>Error: {error.message}</p>}
      {uploadedImages.map((image, index) => (
        <div key={image._id} className="phone-img">
          <img
            src={`http://localhost:5000/${image.path}`}
            alt={image.filename}
          />
          <Link to="#">
            <X
              className="x-square-add remove-product"
              onClick={() => handleRemoveImage(index)}
            />
          </Link>
        </div>
      ))}
      {selectedFiles.map((file, index) => (
        <div key={index} className="phone-img">
          <img
            src={URL.createObjectURL(file)}
            alt={`Selected ${index}`}
          />
          <Link to="#">
            <X
              className="x-square-add remove-product"
              onClick={() => handleRemoveImage(index)}
            />
          </Link>
        </div>
      ))}
    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div className="row select-color-add">
                            <div className="col-lg-6 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Variant Attribute</label>
                                <div className="row">
                                  <div className="col-lg-10 col-sm-10 col-10">
                                    <select
                                      className="form-control variant-select select-option"
                                      id="colorSelect"
                                    >
                                      <option>Choose</option>
                                      <option>Color</option>
                                      <option value="red">Red</option>
                                      <option value="black">Black</option>
                                    </select>
                                  </div>
                                  <div className="col-lg-2 col-sm-2 col-2 ps-0">
                                    <div className="add-icon tab">
                                      <Link
                                        className="btn btn-filter"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-units"
                                      >
                                        <PlusCircle className="feather feather-plus-circle" />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="selected-hide-color"
                                id="input-show"
                              >
                                <div className="row align-items-center">
                                  <div className="col-sm-10">
                                    <div className="input-blocks">
                                      <input
                                        className="input-tags form-control"
                                        id="inputBox"
                                        type="text"
                                        data-role="tagsinput"
                                        name="specialist"
                                        defaultValue="red, black"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-blocks ">
                                      <Link to="#" className="remove-color">
                                        <Trash2 />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="modal-body-table variant-table"
                            id="variant-table"
                          >
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Variantion</th>
                                    <th>Variant Value</th>
                                    <th>SKU</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th className="no-sort">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="color"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="red"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={1234}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="product-quantity">
                                        <span className="quantity-btn">
                                          <i
                                            data-feather="minus-circle"
                                            className="feather-search"
                                          />
                                        </span>
                                        <input
                                          type="text"
                                          className="quntity-input"
                                          defaultValue={2}
                                        />
                                        <span className="quantity-btn">
                                          +
                                          <i
                                            data-feather="plus-circle"
                                            className="plus-circle"
                                          />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={50000}
                                        />
                                      </div>
                                    </td>
                                    <td className="action-table-data">
                                      <div className="edit-delete-action">
                                        <div className="input-block add-lists">
                                          <label className="checkboxs">
                                            <input
                                              type="checkbox"
                                              defaultChecked=""
                                            />
                                            <span className="checkmarks" />
                                          </label>
                                        </div>
                                        <Link
                                          className="me-2 p-2"
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#add-variation"
                                        >
                                          <i
                                            data-feather="plus"
                                            className="feather-edit"
                                          />
                                        </Link>
                                        <Link
                                          className="confirm-text p-2"
                                          to="#"
                                        >
                                          <i
                                            data-feather="trash-2"
                                            className="feather-trash-2"
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="color"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="black"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={2345}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="product-quantity">
                                        <span className="quantity-btn">
                                          <i
                                            data-feather="minus-circle"
                                            className="feather-search"
                                          />
                                        </span>
                                        <input
                                          type="text"
                                          className="quntity-input"
                                          defaultValue={3}
                                        />
                                        <span className="quantity-btn">
                                          +
                                          <i
                                            data-feather="plus-circle"
                                            className="plus-circle"
                                          />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={50000}
                                        />
                                      </div>
                                    </td>
                                    <td className="action-table-data">
                                      <div className="edit-delete-action">
                                        <div className="input-block add-lists">
                                          <label className="checkboxs">
                                            <input
                                              type="checkbox"
                                              defaultChecked=""
                                            />
                                            <span className="checkmarks" />
                                          </label>
                                        </div>
                                        <Link
                                          className="me-2 p-2"
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#edit-units"
                                        >
                                          <i
                                            data-feather="plus"
                                            className="feather-edit"
                                          />
                                        </Link>
                                        <Link
                                          className="confirm-text p-2"
                                          to="#"
                                        >
                                          <i
                                            data-feather="trash-2"
                                            className="feather-trash-2"
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample4"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingFour">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-controls="collapseFour"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list">
                          <h5>
                            <List className="add-info" />
                            <span>Custom Fields</span>
                          </h5>
                          <Link to="#">
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample4"
                  >
                    <div className="accordion-body">
                      <div className="text-editor add-list add">
                        <div className="custom-filed">
                          <div className="input-block add-lists">
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Warranties
                            </label>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Manufacturer
                            </label>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Expiry
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                              <label>Discount Type</label>
                              <Select
                                classNamePrefix="react-select"
                                options={discounttype1}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                              <label>Quantity Alert</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">

                              <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                              <label>Manufacture Time</label>
                              <input type="text" className="form-control" name="manudate" value={formData.manudate} onChange={handleInputChange}/>
                            </div>
                          </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                            <div className="input-blocks add-product">
                              <label>Expiry Time</label>
                              <input type="text" className="form-control" name="exdate" value={formData.exdate} onChange={handleInputChange}/>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <button type="button" className="btn btn-cancel me-2">
                Cancel
              </button>
              <button to={route.addproduct} className="btn btn-submit">
                Save Product
              </button>
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
      <Addunits />
      <AddCategory />
      <AddBrand />
    </div>
  );
};

export default AddProduct;
