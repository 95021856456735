export const purchasesreturn = [
  {
    id: "1",
    img: "assets/img/products/product1.jpg",
    date: "2/27/2022",
    supplier: "Apex Computers",
    reference: "PT001",
    status: "Received",
    grandTotal: "550",
    paid: "120",
    due: "550",
    paymentStatus: "Paid",
  },
  {
    id: "2",
    img: "assets/img/products/product3.jpg",
    date: "1/15/2022",
    supplier: "Modern Automobile",
    reference: "PT002",
    status: "Ordered",
    grandTotal: "550",
    paid: "120",
    due: "550",
    paymentStatus: "Partial",
  },
  {
    id: "3",
    img: "assets/img/products/product2.jpg",
    date: "3/24/2022",
    supplier: "AIM Infotech",
    reference: "PT003",
    status: "Pending",
    grandTotal: "210",
    paid: "120",
    due: "210",
    paymentStatus: "Unpaid",
  },
  {
    id: "4",
    img: "assets/img/products/product5.jpg",
    date: "1/15/2022",
    supplier: "Best Power Tools",
    reference: "PT004",
    status: "Received",
    grandTotal: "210",
    paid: "120",
    due: "210",
    paymentStatus: "Paid",
  },
  {
    id: "5",
    img: "assets/img/products/product6.jpg",
    date: "1/15/2022",
    supplier: "AIM Infotech",
    reference: "PT005",
    status: "Pending",
    grandTotal: "210",
    paid: "120",
    due: "210",
    paymentStatus: "UnPaid",
  },
  {
    id: "6",
    img: "assets/img/products/product4.jpg",
    date: "3/24/2022",
    supplier: "Best Power Tools",
    reference: "PT006",
    status: "Received",
    grandTotal: "210",
    paid: "120",
    due: "210",
    paymentStatus: "Paid",
  },
  {
    id: "7",
    img: "assets/img/products/product8.jpg",
    date: "1/15/2022",
    supplier: "Apex Computers",
    reference: "PT007",
    status: "Ordered",
    grandTotal: "1000",
    paid: "500",
    due: "1000",
    paymentStatus: "Partial",
  },
  {
    id: "8",
    img: "assets/img/products/product9.jpg",
    date: "3/24/2022",
    supplier: "Best Power Tools",
    reference: "PT008",
    status: "Received",
    grandTotal: "210",
    paid: "120",
    due: "210",
    paymentStatus: "Paid",
  },
  {
    id: "9",
    img: "assets/img/products/product10.jpg",
    date: "3/24/2022",
    supplier: "Hatimi Hardware & Tools",
    reference: "PT009",
    status: "Pending",
    grandTotal: "5500",
    paid: "550",
    due: "5500",
    paymentStatus: "Unpaid",
  },
  {
    id: "10",
    img: "assets/img/products/product11.jpg",
    date: "3/24/2022",
    supplier: "Best Power Tools",
    reference: "PT0010",
    status: "Pending",
    grandTotal: "2580",
    paid: "1250",
    due: "2580",
    paymentStatus: "Unpaid",
  },
  {
    id: "11",
    img: "assets/img/products/product12.jpg",
    date: "3/24/2022",
    supplier: "Best Power Tools",
    reference: "PT0011",
    status: "Pending",
    grandTotal: "2580",
    paid: "1250",
    due: "2580",
    paymentStatus: "Unpaid",
  },
];
