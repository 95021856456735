import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { updateSubcategory } from '../../core/redux/subcategory/subcategorySlice';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const EditSubcategories = ({ subcategory }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        category: '',
        name: '',
        code: '',
        description: '',
        status: false
    });

    useEffect(() => {
        console.log("Received subcategory:", subcategory);
        if (subcategory) {
            setFormData({
                category: subcategory.category || '',
                name: subcategory.name || '',
                code: subcategory.code || '',
                description: subcategory.description || '',
                status: subcategory.status || false
            });
        }
    }, [subcategory]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption) => {
        setFormData(prevState => ({
            ...prevState,
            category: selectedOption.value
        }));
    };

    const handleStatusChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            status: e.target.checked
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (subcategory && subcategory._id) {
            try {
                await dispatch(updateSubcategory({
                    _id: subcategory._id,
                    ...formData
                })).unwrap();
                
                MySwal.fire({
                    icon: 'success',
                    title: 'Updated!',
                    text: 'Subcategory has been updated successfully.',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });

                document.querySelector('[data-bs-dismiss="modal"]').click();
            } catch (error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to update subcategory. Please try again.',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-danger'
                    }
                });
            }
        } else {
            console.error('Subcategory or subcategory _id is missing');
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Subcategory data is incomplete. Please try again.',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-danger'
                }
            });
        }
    };

    const categories = [
        { value: 'Choose Category', label: 'Choose Category' },
        { value: 'Category', label: 'Category' },
    ];

    if (!subcategory) {
        return null;
    }

    return (
        <div>
            <div className="modal fade" id="edit-category">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Sub Category</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Parent Category</label>
                                            <Select
                                                classNamePrefix="react-select"
                                                options={categories}
                                                value={categories.find(option => option.value === formData.category)}
                                                onChange={handleSelectChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Category Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Category Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="code"
                                                value={formData.code}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3 input-blocks">
                                            <label className="form-label">Description</label>
                                            <textarea
                                                className="form-control"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-0">
                                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="user3"
                                                    className="check"
                                                    checked={formData.status}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="user3" className="checktoggle" />
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-submit">
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EditSubcategories.propTypes = {
    subcategory: PropTypes.shape({
        _id: PropTypes.string,
        category: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string,
        description: PropTypes.string,
        status: PropTypes.bool
    })
};

export default EditSubcategories;