import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import subcategorySlice from './subcategory/subcategorySlice';
import categorySlice from './category/categorySlice';
import brandSlice from './brand/brandSlice';
import productSlice from './product/productSlice';
import imageReducer from "./imagesupload/imageSlice";
import posReducer from './pos/posSlice';

const store = configureStore({
  reducer: {
    ...rootReducer,
    subcategory: subcategorySlice,
    category:categorySlice,
    brand:brandSlice,
    product:productSlice,
    images: imageReducer,
    pos: posReducer,
    
  }
});

export default store;