import React from 'react';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import PropTypes from 'prop-types';

const ProductDetail = ({ product }) => {
    console.log("full product Data" , product)
//   if (!product) {
//     return null;
//   }

  return (
    <div className="product-detail-wrapper">
      <div className="page-header">
        <div className="page-title">
          <h4>Product Details</h4>
          <h6>Full details of a product</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="productdetails">
                <ul className="product-bar">
                  <li>
                    <h4>Product</h4>
                    <h6>{product.title || 'N/A'}</h6>
                  </li>
                  <li>
                    <h4>Category</h4>
                    <h6>{product.category || 'N/A'}</h6>
                  </li>
                  <li>
                    <h4>Brand</h4>
                    <h6>{product.brand || 'N/A'}</h6>
                  </li>
                  <li>
                    <h4>Price</h4>
                    <h6>{product.price || 'N/A'}</h6>
                  </li>
                  <li>
                    <h4>SKU</h4>
                    <h6>{product.sku || 'N/A'}</h6>
                  </li>
                  <li>
                    <h4>Quantity</h4>
                    <h6>{product.quantity || 'N/A'}</h6>
                  </li>
                  {/* Add more details as needed */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="slider-product-details">
                <div className="slider-product">
                  {product.image && <ImageWithBasePath src={product.image} alt="product" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ProductDetail.propTypes = {
    product: PropTypes.shape({
      title: PropTypes.string,
      category: PropTypes.string,
      brand: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sku: PropTypes.string,
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image: PropTypes.string
    })
  };
  
//   ProductDetail.defaultProps = {
//     product: null
//   };
  
export default ProductDetail;