import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://hrithik-pos.vercel.app/api/subcategory";

// Create subcategory
export const addSubcategory = createAsyncThunk(
  "subcategory/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(BASE_URL, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch subcategories
export const fetchSubcategories = createAsyncThunk(
  "subcategory/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(BASE_URL);
      console.log("Fetched data:", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update subcategory
export const updateSubcategory = createAsyncThunk(
  "subcategory/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/${data._id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete subcategory
export const deleteSubcategory = createAsyncThunk(
  "subcategory/delete",
  async (_id, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/${_id}`);
      return _id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subcategorySlice = createSlice({
  name: "subcategory",
  initialState: {
    subcategories: [],
    loading: false,
    error: null,
    searchResults: [],
  },
  reducers: {
    searchSubcategories: (state, action) => {
      state.searchResults = state.subcategories.filter(subcategory => 
        subcategory.name.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(addSubcategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSubcategory.fulfilled, (state, action) => {
        state.loading = false;
        state.subcategories.push(action.payload);
      })
      .addCase(addSubcategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Read
      .addCase(fetchSubcategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubcategories.fulfilled, (state, action) => {
        state.loading = false;
        state.subcategories = action.payload;
      })
      .addCase(fetchSubcategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update
      .addCase(updateSubcategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubcategory.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.subcategories.findIndex(subcategory => subcategory._id === action.payload._id);
        if (index !== -1) {
          state.subcategories[index] = action.payload;
        }
      })
      .addCase(updateSubcategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete
      .addCase(deleteSubcategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSubcategory.fulfilled, (state, action) => {
        state.loading = false;
        state.subcategories = state.subcategories.filter(subcategory => subcategory._id !== action.payload);
      })
      .addCase(deleteSubcategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { searchSubcategories } = subcategorySlice.actions;
export default subcategorySlice.reducer;