// src/data/products.js
const products = [
    {
      id: 1,
      name: 'Timex Black Silver',
      category: 'Watches',
      image: 'assets/img/products/pos-product-09.png',
      price: 1457,
      quantity: 24,
    },
    {
      id: 2,
      name: 'Fossil Pair Of 3 in 1',
      category: 'Watches',
      image: 'assets/img/products/pos-product-11.png',
      price: 789,
      quantity: 40,
    },
    {
      id: 3,
      name: 'Green Nike Fe',
      category: 'Shoes',
      image: 'assets/img/products/pos-product-18.png',
      price: 7847,
      quantity: 78,
    },
    {
        id: 4,
        name: 'laptops',
        category: 'Laptops',
        image: 'assets/img/products/pos-product-02.png',
        price: 1457,
        quantity: 24,
      },
      {
        id: 5,
        name: 'Fossil Pair Of 3 in 1',
        category: 'Mobiles',
        image: 'assets/img/products/pos-product-01.png',
        price: 789,
        quantity: 40,
      },
      {
        id: 6,
        name: 'shoes',
        category: 'Shoes',
        image: 'assets/img/products/pos-product-06.png',
        price: 7847,
        quantity: 78,
      },
      {
        id: 7,
        name: 'Timex Black Silver',
        category: 'Mobiles',
        image: 'assets/img/products/pos-product-08.png',
        price: 1457,
        quantity: 24,
      },
      {
        id: 8,
        name: 'Fossil Pair Of 3 in 1',
        category: 'Watches',
        image: 'assets/img/products/pos-product-09.png',
        price: 789,
        quantity: 40,
      },
      {
        id: 9,
        name: 'laptop',
        category: 'Laptops',
        image: 'assets/img/products/pos-product-12.png',
        price: 7847,
        quantity: 78,
      },
      {
        id: 10,
        name: 'mobile',
        category: 'Mobiles',
        image: 'assets/img/products/pos-product-05.png',
        price: 7847,
        quantity: 78,
      },
  ];
  
  export default products;