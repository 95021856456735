import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://hrithik-pos.vercel.app/api/product";

// Create product
export const addProductFunction = createAsyncThunk( "product/create",async (data, { rejectWithValue }) => {
    console.log("Post FData:", data)
    try {
      const response = await axios.post(BASE_URL, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch product
export const FetchProductFunction = createAsyncThunk("product/fetchAll",async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(BASE_URL);
      console.log("Fetched data:", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update product
export const updateProductFunction = createAsyncThunk("product/update",async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/${data._id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete product
export const deleteProductFunction = createAsyncThunk("product/delete",async (_id, { rejectWithValue }) => {
    console.log("ID",_id)
    try {
      await axios.delete(`${BASE_URL}/${_id}`);
      console.log("this is a id",_id)
      return _id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    product: [],
    loading: false,
    error: null,
    searchResults: [],
  },
  reducers: {
    searchProduct: (state, action) => {
      state.searchResults = state.product.filter(product => 
        product.name.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(addProductFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.product.push(action.payload);
      })
      .addCase(addProductFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Read
      .addCase(FetchProductFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchProductFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload;
      })
      .addCase(FetchProductFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update
      .addCase(updateProductFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductFunction.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.product.findIndex(product => product._id === action.payload._id);
        if (index !== -1) {
          state.product[index] = action.payload;
        }
      })
      .addCase(updateProductFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete
      .addCase(deleteProductFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProductFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.product = state.product.filter(product => product._id !== action.payload);
      })
      .addCase(deleteProductFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { searchProduct } = productSlice.actions;
export default productSlice.reducer;