import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "https://hrithik-pos.vercel.app/api/brand";

// Create brand
export const addBrandFunction = createAsyncThunk( "brand/create",async (data, { rejectWithValue }) => {
    console.log("Post FData:", data)
    try {
      const response = await axios.post(BASE_URL, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch brand
export const FetchBrandFunction = createAsyncThunk("brand/fetchAll",async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(BASE_URL);
      console.log("Fetched data:", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update brand
export const updateBrandFunction = createAsyncThunk("brand/update",async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/${data._id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete brand
export const deleteBrandFunction = createAsyncThunk("brand/delete",async (_id, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/${_id}`);
      return _id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brand: [],
    loading: false,
    error: null,
    searchResults: [],
  },
  reducers: {
    searchBrand: (state, action) => {
      state.searchResults = state.brand.filter(brand => 
        brand.name.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(addBrandFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBrandFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.brand.push(action.payload);
      })
      .addCase(addBrandFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Read
      .addCase(FetchBrandFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchBrandFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.brand = action.payload;
      })
      .addCase(FetchBrandFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update
      .addCase(updateBrandFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBrandFunction.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.brand.findIndex(brand => brand._id === action.payload._id);
        if (index !== -1) {
          state.brand[index] = action.payload;
        }
      })
      .addCase(updateBrandFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete
      .addCase(deleteBrandFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBrandFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.brand = state.brand.filter(brand => brand._id !== action.payload);
      })
      .addCase(deleteBrandFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { searchBrand } = brandSlice.actions;
export default brandSlice.reducer;